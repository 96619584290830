<template>
  <div class="businessNeeds">
    <div class="title marB10 flex">
      <div class="left br4 flex">
        <h1 class="h1">心理预约筛选：</h1>
        <div class="left">
          <el-select
            v-model="queryParam.status"
            @change="selectneedsStatus"
            placeholder="全部状态"
            size="mini"
            class="select_input img w150 marR20"
          >
            <el-option label="未受理" value="0"></el-option>
            <el-option label="预约失败" value="1"></el-option>
            <el-option label="预约成功" value="2"></el-option>
          </el-select>
        </div>
      </div>
      <div class="needs_imgs" @click="goRouter('/heartOrder')">
        <img src="@/static/public/hearts.png" alt />
      </div>
    </div>
    <div class="offline_content">
      <div class="headers">
        <div class="tab_content">
          <div
            class="tables loads"
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
          >
            <el-table :data="tableData" style="width: 100%" stripe>
              <el-table-column prop="createTime" align="center" label="预约时间">
                <template slot-scope="scope">
                  <span>{{scope.row.appointDate||'暂无'}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="backeTime" align="center" label="预约电话">
                <template slot-scope="scope">
                  <span>{{scope.row.mobile||'暂无'}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="status" align="center" label="预约状态">
                <template slot-scope="scope">
                  <span v-if="scope.row.status==='0'" style="color:#FC6C38">预约成功</span>
                  <span v-if="scope.row.status==='1'" style="color:#00924B">预约中</span>
                  <span v-if="scope.row.status==='2'" style="color:#E70E0E">预约失败</span>
                </template>
              </el-table-column>
              <el-table-column prop="checkcontent" align="center" label="咨询简述">
                <template slot-scope="scope">
                  <span>{{scope.row.description||'暂无简述'}}</span>
                </template>
              </el-table-column>
            </el-table>
            <div class="pagination">
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                class="work_pagination"
                :current-page.sync="queryParam.pageNum"
                :page-sizes="[5, 10, 15, 20]"
                :page-size="queryParam.pageSize"
                layout="total,prev, pager, next, jumper"
                :prev-text="pagination.pre"
                :next-text="pagination.next"
                :total="total"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
    <el-dialog title="预约详情" :visible.sync="dialogVisible" :width="width" :before-close="close">
      <div>
        <p>预约时间：</p>
        <p>预约时间：</p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Needs from "components/enterprise/Needs";
export default {
  name: "businessNeeds",
  components: {
    Needs,
  },
  data() {
    return {
      loading: false,
      comInfo: {},
      dialogVisible: false,
      type: "",
      needList: [],
      viewNeedsDatail: {},
      selectValue: "",
      input: "",
      width: "590px;",
      title: "",
      total: 0,
      queryParam: {
        pageNum: 1,
        pageSize: 6,
        status: "2",
      },
      tableData: [],
      pagination: {
        pre: "上一页",
        next: "下一页",
      },
    };
  },
  watch: {
    // dialogVisible: "refresh"
  },
  methods: {
    getDetail() {
      this.dialogVisible = true;
    },
    goRouter(url, id, type) {
      const { href } = this.$router.resolve({
        path: url,
      });
      window.open(href, "_blank");
    },
    async needsList() {
      this.loading = true;
      let res = await this.$api.getMyapplyTest(this.queryParam);
      console.log(res.data);
      if (res.data.success) {
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
         this.loading = false;
      } else {
        // this.$message.error(res.data.message);
         this.loading = false;
      }
      this.loading = false;
    },

    // 查询
    selectNeedsType(value) {
      this.queryParam.needsType = value;
      this.queryParam.pageNum = 1;
      this.needsList();
    },
    selectneedsStatus(value) {
      this.queryParam.status = value;
      this.queryParam.pageNum = 1;
      this.needsList();
    },
    refresh() {
      if (!this.dialogVisible) {
        this.needsList();
      }
    },
    close(val) {
      this.dialogVisible = false;
    },
    // 分页
    handleCurrentChange(val) {
      this.queryParam.pageNum = val;
      this.needsList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.needsList();
    },
    opens(title, type) {
      this.title = title;
      this.width = "590px;";
      this.type = type;
      this.dialogVisible = true;
    },

    Close() {
      this.dialogVisible = false;
    },
  },
  created() {
    this.needsList();
    if (this.$store.state.login) {
      let memberType = this.$store.state.login.userInfo.memberType;
      this.memberType = memberType;
    }
  },
};
</script>

<style scoped lang="less">
.businessNeeds {
  padding: 0px;
  margin: 0px;
  .title {
    align-items: center;
    height: 64px;
    line-height: 64px;
    .left {
      background: #ffffff;
      height: 100%;
      padding: 0 20px;
      h1 {
        color:#00B556;
        font-weight: normal;
        font-size: 18px;
      }
      margin-right: 16px;
      flex: 1;
    }
    .needs_imgs {
      width: 288px;
      height: 64px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 55%;
}
.tables {
  background: #ffffff;
  padding: 20px;
  .el-table {
    margin-bottom: 20px;
  }
}
/deep/ .el-loading-spinner {
  top: 20%;
}
</style>
